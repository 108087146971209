<template>
  <div v-if="isPasswordHelp" class="flex flex-col justify-items-start relative mb-0">
    <div class="mb-0 relative">
      <input
        :id="$attrs['element-id']"
        :autocomplete="`off`"
        :class="{
          disabled: $attrs.readonly,
          'border-red-500': hasError
        }"
        :data-test-id="$attrs['element-id']"
        :model-value="modelValue"
        :placeholder="placeHolder"
        :tabindex="tabindex"
        :type="passwordFieldType"
        :value="modelValue"
        class="appearance-none border rounded w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline z-30 placeholder:text-slate-400 focus:border-sky-500 focus:ring-2 placeholder:text-sm placeholder:2xl:text-lg"
        @blur="isHelpOpen = false"
        @input="
          $emit('update:modelValue', $event.target.value);
          isHelpOpen = true;
        "
        @keyup="checkPassword"
      />
      <span
        v-if="showPasswordToggle"
        class="absolute right-2 top-2 cursor-pointer bg-no-repeat bg-right bg-origin-content fill-cyan-500 w-5 h-5"
        @click="handlePassowrdField"
      ><SvgSprite :symbol="getPassIcon()" class="icon w-full h-full fill-gray-400"
      /></span>
    </div>

    <ul v-show="isHelpOpen && hasError" class="top-10 absolute border p-2 bg-gray-50 w-full z-50">
      <li
        v-for="help in passwordHelps"
        :key="help.key"
        :class="`${!help.valid ? 'text-red-600' : 'text-green-600'}`"
        class="flex flex-row items-center overflow-hidden gap-1 px-2 py-1"
      >
        <SvgSprite class="icon stroke-none fill-gray-600 w-4 h-4 mr-2" symbol="right-tick" />
        <p>{{ help.message }}</p>
      </li>
    </ul>
  </div>
  <div v-else class="flex flex-col justify-items-start relative">
    <input
      :id="$attrs['element-id']"
      :autocomplete="`off`"
      :class="{
        disabled: $attrs.readonly,
        'border-red-500': hasError
      }"
      :data-test-id="$attrs['element-id']"
      :model-value="modelValue"
      :placeholder="placeHolder"
      :tabindex="tabindex"
      :type="passwordFieldType"
      :value="modelValue"
      class="appearance-none border rounded w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-400 focus:border-sky-500 focus:ring-2 placeholder:text-sm placeholder:2xl:text-lg"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <span
      v-if="showPasswordToggle"
      class="absolute right-2 top-2 cursor-pointer bg-no-repeat bg-right bg-origin-content fill-cyan-500 w-5 h-5"
      @click="handlePassowrdField"
    >
      <SvgSprite :symbol="getPassIcon()" class="icon w-full h-full fill-gray-400" />
    </span>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { SvgSprite } from 'vue-svg-sprite';
import { useI18n } from '#imports';
import { PASSWORD_MAX_LENGTH } from '@/utils/constants';
import Validation from '@/validations/Validations';

const { t } = useI18n();
const passwordFieldType = ref('password');
const isHelpOpen = ref(false);
defineEmits(['update:modelValue']);
defineOptions({
  inheritAttrs: false
});
defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  isPasswordHelp: {
    type: Boolean,
    default: false
  },
  showPasswordToggle: {
    type: Boolean,
    default: true
  },
  tabindex: {
    type: Number,
    default: 0
  },
  inputClass: {
    type: String,
    default: 'form-input'
  },
  placeHolder: {
    type: String,
    default: ''
  },
  hasError: {
    type: Boolean,
    default: false
  }
});
const passLenggth = ref(PASSWORD_MAX_LENGTH);
const passwordHelps = ref([
  {
    key: 'minLength',
    valid: false,
    message: t('inputs.errors.password.minlength').replace('###', passLenggth.value)
  },
  {
    key: 'oneLCase',
    valid: false,
    message: t('inputs.errors.password.one_lowercase')
  },
  {
    key: 'oneUCase',
    valid: false,
    message: t('inputs.errors.password.one_uppercase')
  },
  {
    key: 'oneNumber',
    valid: false,
    message: t('inputs.errors.password.one_number')
  },
  {
    key: 'oneSpecialChar',
    valid: false,
    message: t('inputs.errors.password.one_special_char').replace('###', '!@#$%^&*')
  }
]);
const handlePassowrdField = () => {
  passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
};

const getPassIcon = () => {
  return passwordFieldType.value === 'password' ? 'eys-password' : 'eys-password-view';
};

const checkPassword = (e) => {
  const inputValue = e.target.value;
  passwordHelps.value = passwordHelps.value.map((item) => {
    item.valid = false;
    if (item.key === 'minLength') {
      item.valid = Validation[item.key](inputValue, passLenggth.value);
    } else {
      item.valid = !Validation[item.key](inputValue);
    }
    return item;
  });
};
</script>
