<template>
  <div :class="`${$attrs.class}${error ? ' error' : ''}`">
    <ElementsInputsTheLabel :label="label" class="flex items-center gap-1 mb-2 text-gray-600 text-sm font-semibold">
      <ClearLink
        v-if="lblTooltip?.length"
        v-tooltip.right="{
          content: lblTooltip.replace('_', ' '),
          html: true
        }"
        :element-id="`lbl-${elementId}`"
        class="self-start mt-1 capitalize"
        to-link="javascript:;"
      >
        <SvgSprite class="w-4 h-4" symbol="tooltip" />
      </ClearLink>
    </ElementsInputsTheLabel>
    <ElementsInputsTheDropDown
      v-if="type === 'select'"
      :change-func="changeFunc"
      :element-id="elementId"
      :has-error="error"
      :image-key="imageKey"
      :is-image="isImage"
      :model-value="modelValue"
      :option-key="optionKey"
      :option-value="optionValue"
      :options="options()"
      :place-holder="placeHolder"
      :read-only="readOnly"
      :tabindex="tabindex"
      v-bind="$attrs"
    ></ElementsInputsTheDropDown>
    <ElementsInputsThePassword
      v-else-if="type === 'password'"
      :element-id="elementId"
      :has-error="error"
      :model-value="modelValue"
      :place-holder="placeHolder"
      :show-password-toggle="showPasswordToggle"
      :tabindex="tabindex"
      v-bind="$attrs"
    ></ElementsInputsThePassword>
    <ElementsInputsTheTextarea
      v-else-if="type === 'textarea'"
      :class="`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        readOnly ? ' bg-gray-200' : ''
      }`"
      :element-id="elementId"
      :has-error="error"
      :model-value="modelValue"
      :placeholder="placeHolder"
      :read-only="readOnly"
      :tabindex="tabindex"
      v-bind="$attrs"
    ></ElementsInputsTheTextarea>
    <ElementsInputsStartEnd
      v-else-if="type === 'startEndInput'"
      :class="`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`"
      :element-id="elementId"
      :error-message="errorMessage"
      :has-error="error"
      :initial-input="initialInput"
      :model-value="modelValue"
      :placeholder="placeHolder"
      :read-only="readOnly"
      :tabindex="tabindex"
      v-bind="$attrs"
    ></ElementsInputsStartEnd>
    <ElementsInputsTheNumberInput
      v-else-if="type === 'number'"
      :class="`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline${
        readOnly ? ' bg-gray-200' : ''
      }`"
      :element-id="elementId"
      :has-error="error"
      :model-value="modelValue"
      :placeholder="placeHolder"
      :readonly="readOnly"
      :tabindex="tabindex"
      type="text"
      v-bind="$attrs"
    ></ElementsInputsTheNumberInput>
    <ElementsInputsTheLabelInput
      v-else-if="type === 'label'"
      :class="`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200`"
      :element-id="elementId"
      :model-value="modelValue"
      :tabindex="tabindex"
      v-bind="$attrs"
    ></ElementsInputsTheLabelInput>
    <ElementsInputsTheInput
      v-else
      :class="`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline${
        readOnly ? ' bg-gray-200' : ''
      }`"
      :element-id="elementId"
      :has-error="error"
      :model-value="modelValue"
      :placeholder="placeHolder"
      :readonly="readOnly"
      :tabindex="tabindex"
      :type="type"
      v-bind="$attrs"
    ></ElementsInputsTheInput>
    <slot />
    <TheInputError :error-message="errorMessage" :is-show="error && isShowMessage"></TheInputError>
  </div>
</template>
<script setup>
import { reactive, watchEffect } from 'vue';

defineOptions({
  inheritAttrs: false
});
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    required: true
  },
  elementId: {
    type: String,
    required: true
  },
  modelValue: {
    type: [Number, String, Boolean, Array, Object],
    default: ''
  },
  inputClass: {
    type: String,
    default: 'form-input'
  },
  options: {
    type: Function,
    default: () => []
  },
  optionKey: {
    type: String,
    default: 'id'
  },
  optionValue: {
    type: String,
    default: 'name'
  },

  isImage: {
    type: Boolean,
    default: false
  },
  imageKey: {
    type: String,
    default: 'name'
  },
  changeFunc: {
    type: Function,
    default: () => undefined
  },
  tabindex: {
    type: Number,
    default: 0
  },
  placeHolder: {
    type: String,
    default: ''
  },
  errorDivClass: { type: String, default: 'mb-0' },
  error: {
    type: Boolean,
    default: false
  },
  isShowMessage: {
    type: Boolean,
    default: true
  },
  // value: {
  //   type: [Number, String, Boolean, Array, Object],
  //   default: "",
  // },
  errorMessage: {
    type: String,
    default: ''
  },
  showPasswordToggle: {
    type: Boolean,
    default: true
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  initialInput: {
    type: Number,
    default: 0
  },
  lblTooltip: {
    type: String,
    default: ''
  }
});
const divClasses = reactive([]);
watchEffect(() => {
  if (props.error) divClasses.push('error');
});
</script>
