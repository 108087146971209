<template>
  <div :class="`${hasError ? 'error' : ''}`" class="flex justify-between gap-3">
    <ElementsInputsTheInput
      :element-id="`${elementId.replaceAll('_', '-')}`"
      :has-error="hasError"
      :placeholder="placeHolder"
      :tabindex="tabindex"
      :type="`hidden`"
      :value="modelValue"
    ></ElementsInputsTheInput>
    <div :class="`w-1/2 flex flex-col gap-1 ${hasError ? 'error' : ''}`">
      <ElementsInputsTheLabel
        :is-bold="false"
        :label="`${$t('assets.from')} (${$t('assets.year')})`"
      ></ElementsInputsTheLabel>

      <ElementsInputsTheInput
        v-model="startInput"
        v-maska
        :class="`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline${
          readOnly ? ' bg-gray-200' : ''
        }`"
        :element-id="`${elementId.replaceAll('_', '-')}-start`"
        :has-error="startHasError"
        :placeholder="placeHolder"
        :readonly="readOnly"
        :tabindex="tabindex"
        :type="`text`"
        data-maska="####"
        data-maska-eager
        @change="(evt) => handleInputValue(evt)"
      ></ElementsInputsTheInput>
      <TheInputError :error-message="startErrorMessage" :is-show="startHasError"></TheInputError>
    </div>
    <div :class="`w-1/2 flex flex-col gap-1 ${hasError ? 'error' : ''}`">
      <ElementsInputsTheLabel
        :is-bold="false"
        :label="`${$t('assets.to')} (${$t('assets.year')})`"
      ></ElementsInputsTheLabel>
      <ElementsInputsTheInput
        v-model="endInput"
        v-maska
        :class="`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline${
          readOnly ? ' bg-gray-200' : ''
        }`"
        :element-id="`${elementId.replaceAll('_', '-')}-end`"
        :has-error="endHasError"
        :placeholder="placeHolder"
        :readonly="readOnly"
        :tabindex="tabindex + 1"
        :type="`text`"
        data-maska="####"
        data-maska-eager
        @change="(evt) => handleInputValue(evt, true)"
      ></ElementsInputsTheInput>
      <TheInputError :error-message="endErrorMessage" :is-show="endHasError"></TheInputError>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { onBeforeMount, useI18n } from '#imports';

defineOptions({
  inheritAttrs: false
});
const emit = defineEmits(['update:modelValue', 'change']);
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  tabindex: {
    type: Number,
    default: 0
  },
  elementId: {
    type: String,
    required: true
  },
  errorMessage: {
    type: String,
    default: ''
  },
  hasError: {
    type: Boolean,
    default: false
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  placeHolder: {
    type: String,
    default: ''
  },
  initialInput: {
    type: Number,
    default: 0
  }
});
const startInput = ref('');
const endInput = ref('');
const startHasError = ref(false);
const endHasError = ref(false);
const startErrorMessage = ref('');
const endErrorMessage = ref('');
const { t } = useI18n();

onBeforeMount(() => {
  emit('update:modelValue', '');
  if (props.modelValue !== '') {
    const modelValueSplit = props.modelValue.split(' - ');
    if (modelValueSplit.length) {
      startInput.value = modelValueSplit[0];
      endInput.value = modelValueSplit[1];
    }
  }
  endHasError.value = props.hasError;
  startHasError.value = props.hasError;
});
watch(
  props,
  (newProps) => {
    endHasError.value = newProps.hasError;
    startHasError.value = newProps.hasError;
    startErrorMessage.value = newProps.errorMessage;
    endErrorMessage.value = newProps.errorMessage;
  },
  {
    deep: true
  }
);
const range = (start, end) => {
  const ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
};
const handleInputValue = (evt, isEnd = false) => {
  const currentYear = new Date().getFullYear();
  const yearRange = range(1970, currentYear);
  endHasError.value = props.hasError;
  startHasError.value = props.hasError;
  startErrorMessage.value = props.errorMessage;
  endErrorMessage.value = props.errorMessage;
  if (!endInput.value && yearRange.includes(+startInput.value.trim())) {
    endInput.value = startInput.value;
  }
  if (!startInput.value) {
    endInput.value = '';
  }
  if (evt.target.value && yearRange.includes(+evt.target.value.trim())) {
    if (props.initialInput > 0 && Number(startInput.value) <= Number(props.initialInput)) {
      emit('update:modelValue', '');
      startErrorMessage.value = t('inputs.errors.year_input.invalidMatcherError');
      startHasError.value = true;
    } else if (Number(startInput.value) > Number(+endInput.value)) {
      endErrorMessage.value = t('inputs.errors.year_input.invalidToDateError');
      endHasError.value = true;
    } else {
      startHasError.value = false;
      endHasError.value = false;
      const singleValue = `${startInput.value} - ${endInput.value}`;
      emit('update:modelValue', singleValue);
    }
  } else {
    if (isEnd && Number(endInput.value) > currentYear) {
      endErrorMessage.value = t('inputs.errors.year_input.invalidValueError');
      endHasError.value = true;
    } else if (Number(endInput.value) < Number(startInput.value)) {
      endErrorMessage.value = t('inputs.errors.year_input.invalidToDateError');
      endHasError.value = true;
    } else {
      endErrorMessage.value = props.errorMessage;
      endHasError.value = true;
    }
    if (Number(startInput.value) > currentYear) {
      startErrorMessage.value = t('inputs.errors.year_input.invalidValueError');
      startHasError.value = true;
    } else {
      startErrorMessage.value = props.errorMessage;
      startHasError.value = true;
    }
  }
};
</script>
